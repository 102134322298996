import React from 'react';
import styled from 'styled-components';
import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import CreditCardIcon from '../images/icons/credit-card.svg';
import { useI18n } from '../hooks';

type PaymentDisclaimerProps = {
  isMobile?: boolean;
  isClock?: boolean;
};

const Container = styled.span<PaymentDisclaimerProps>`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  border: 1px solid ${colors.irisBlue};
  border-radius: 6px;
  padding: 16px;
  color: ${colors.irisBlue};
  font-size: 15px;
  max-width: 345px;
  width: 95%;
  justify-self: flex-start;

  ${below('mobile')} {
    margin: auto;
    margin-bottom: 20px;

    display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};
  }
`;

const Icon = styled.img`
  width: 34px;
  margin-right: 15px;
`;

const PaymentDisclaimer: React.FC<PaymentDisclaimerProps> = ({
  isMobile,
  isClock
}) => {
  const { t } = useI18n();

  return (
    <Container id="paymentBanner" isMobile={isMobile}>
      <Icon src={CreditCardIcon} />
      {isClock
        ? t`This clock face requires a payment that is managed
      by the developer in-app.`
        : t`This app requires a payment that is managed
      by the developer in-app.`}
    </Container>
  );
};

export default PaymentDisclaimer;
