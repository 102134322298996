import React, { useCallback } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';
import { stringify } from 'query-string';
import { useClipboard } from 'use-clipboard-copy';

import { App } from '../types';
import ScreenshotSelector from './ScreenshotSelector';

import Disclaimer from './Disclaimer';

import {
  GetEEAUserVariables,
  GetEEAUserResponse,
  getEEAUser
} from '../graphql/getPageLayout';
import { ChildDataProps, graphql } from 'react-apollo';

import {
  InfoText,
  BannerTitle,
  BaseSection,
  baseFitLinkStyle,
  NoStyleLink
} from './basic-components';
import { extractAccessKeyFromLocation } from '../utils';
import { colors } from '../style-constants';
import { below, above } from '../media-query-utils';
import SupportedDevices from './SupportedDevices';
import PremiumNotice from './PremiumNotice';
import { OpenAppLink } from '../pages/MobileAppRedirect';

import phoneIcon from '../images/icons/phone.svg';
import twitterIcon from '../images/icons/twitter.svg';
import facebookIcon from '../images/icons/facebook.svg';
import copyIcon from '../images/icons/copy.svg';
import checkIcon from '../images/icons/check-green.svg';
import { useI18n } from '../hooks';
import { getAppScreenshots } from '../utils';

import alertIcon from '../images/icons/ic_alert.png';

export type Props = {
  app: App;
  clientIpAddress?: string;
};

type ChildProps = ChildDataProps<
  Props,
  GetEEAUserVariables,
  GetEEAUserResponse
>;

type ShareIconProps = {
  link: string;
};

type OpenConfig = {
  [key: string]: string | number;
};

const Wrapper = styled.div`
  width: 100%;

  background-color: ${colors.white};
`;

const Section = styled(BaseSection)`
  display: flex;
  padding: 50px 0;

  ${below('mobile')} {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  & > div {
    ${below('mobile')} {
      margin: 0;
    }
  }
`;

const BannerImageSection = styled.div`
  display: flex;
  flex-direction: column;

  padding-right: 25px;
  align-items: flex-end;

  ${above('mobile')} {
    flex: 0 0 50%;
  }

  ${below('mobile')} {
    order: 1;
    padding: 0;
    padding-bottom: 45px;
  }
`;

const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 25px;

  align-items: flex-start;
  justify-content: center;

  ${below('mobile')} {
    flex-flow: row wrap;
    min-width: 300px;
    padding: 0;

    justify-content: flex-start;
  }
`;

const ReportButton = styled.a`
  cursor: pointer;
  background: #112530;

  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  padding-left: 15px;
  padding-right: 10px;
  margin-left: 10px;
`;

const ReportButtonContainer = styled.div`
  display: flex;
`;

const ReportButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.28px;
  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 0px 4px;
`;

const InfoSign = styled.img`
  display: flex;
  height: 18px;
  align-self: center;
`;

const useWindowOpen = (url: string, config: OpenConfig = {}) => {
  const openConfig: OpenConfig = {
    width: 550,
    height: 400,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...config
  };

  const configString = Object.keys(openConfig)
    .map((key) => `${key}=${openConfig[key]}`)
    .join(', ');

  return useCallback(
    (event: React.SyntheticEvent) => {
      window.open(url, '', configString);
      event.preventDefault();
    },
    [url, configString]
  );
};

const ShareIconBase = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;

  align-items: center;
  justify-content: center;

  border-radius: 15px;

  background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor};
`;

const FacebookIcon = styled.img.attrs(() => ({
  src: facebookIcon
}))`
  width: 19px;
  height: 19px;

  color: ${colors.white};
`;

const FacebookShareIcon: React.FC<ShareIconProps> = ({ link }) => {
  const { t } = useI18n();
  const url = `https://www.facebook.com/sharer/sharer.php?${stringify({
    u: link,
    hashtag: '#made4fitbit'
  })}`;

  const openWindow = useWindowOpen(url);

  return (
    <ShareIconBase
      title={t`Share on Facebook`}
      href={url}
      target="_blank"
      backgroundColor={colors.facebookBlue}
      onClick={openWindow}
    >
      <FacebookIcon />
    </ShareIconBase>
  );
};

const TwitterIcon = styled.img.attrs(() => ({
  src: twitterIcon
}))`
  width: 21px;
  height: 21px;

  color: ${colors.white};
`;

const TwitterShareIcon: React.FC<ShareIconProps> = ({ link }) => {
  const { t } = useI18n();
  const url = `https://twitter.com/share?${stringify({
    url: link,
    hashtags: 'made4fitbit'
  })}`;

  const openWindow = useWindowOpen(url);

  return (
    <ShareIconBase
      title={t`Share on Twitter`}
      href={url}
      target="_blank"
      backgroundColor={colors.twitterBlue}
      onClick={openWindow}
    >
      <TwitterIcon />
    </ShareIconBase>
  );
};

const CopyIcon = styled.img.attrs(() => ({
  src: copyIcon
}))`
  width: 18px;
  height: 18px;
  margin-right: 10px;

  color: ${colors.white};
`;

const CheckIcon = styled(CopyIcon).attrs(() => ({
  src: checkIcon
}))``;

const CopyShareIconBase = styled(ShareIconBase)`
  width: auto;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px;

  ${baseFitLinkStyle}

  border: 1px solid ${colors.irisBlue};
  color: ${({ color }) => color};
  font-family: 'Proxima Nova Bold', Arial, sans-serif;
  text-transform: uppercase;

  transition: background-color 500ms, color 500ms;
`;

const CopyShareIcon: React.FC<ShareIconProps> = ({ link }) => {
  const { t } = useI18n();
  const clipboard = useClipboard({
    copiedTimeout: 3000
  });

  const copyUrl = useCallback(
    (event: React.SyntheticEvent) => {
      clipboard.copy(link);

      event.preventDefault();
    },
    [link]
  );

  return (
    <CopyShareIconBase
      title={t`Copy link to clipboard`}
      href={link}
      target="_blank"
      color={clipboard.copied ? colors.irisBlue : colors.white}
      backgroundColor={clipboard.copied ? colors.white : colors.irisBlue}
      onClick={copyUrl}
    >
      {clipboard.copied ? (
        <React.Fragment>
          <CheckIcon /> {t`copied!`}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CopyIcon /> {t`copy`}
        </React.Fragment>
      )}
    </CopyShareIconBase>
  );
};

type ReportButtonProps = {
  app: App;
};

const ReportAppButton: React.FC<ReportButtonProps> = ({ app }) => {
  const { t } = useI18n();

  const sendEmail = () => {
    const encodedBody = encodeURIComponent(
      `I am reporting this app because: \r\r${app.name} ${window.location.href}`
    );
    window.location.href = `mailto:appgallerysupport@fitbit.com?subject=App Gallery Private App Report&body=${encodedBody}`;
  };

  return (
    <ReportButton onClick={() => sendEmail()}>
      <ReportButtonContainer>
        <InfoSign src={alertIcon} />
        <ReportButtonTextContainer>{t`Report`}</ReportButtonTextContainer>
      </ReportButtonContainer>
    </ReportButton>
  );
};

const ShareIcons = styled.div`
  display: flex;

  ${above('mobile')} {
    margin-bottom: 30px;
  }
`;

const Icon = styled.img`
  margin-bottom: 20px;
  margin-right: 22px;
  width: 60px;
  height: 60px;

  border-radius: 8px;
  border: 1px solid ${colors.whisper};
  padding: 5px;

  ${below('mobile')} {
    width: 100px;
    height: 100px;
    padding: 10px;
  }
`;

const BannerInfoHeader = styled.div`
  ${below('mobile')} {
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
  }
`;

const MobileAppInfoText = styled(InfoText)`
  display: flex;
  width: 100%;

  align-items: center;

  font-size: 16px;
  line-height: 1.38;

  ${below('mobile')} {
    background-color: ${colors.whiteSmoke};
    padding: 25px 16px;
  }
`;

const PhoneIcon = styled.img`
  padding-right: 15px;

  ${below('mobile')} {
    padding-right: 20px;
  }
`;

const DesktopView = styled.div`
  display: none;

  ${above('mobile')} {
    display: block;
  }
`;

const MobileShareView = styled.div`
  display: flex;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;

  align-items: center;
  justify-content: space-between;

  background-color: ${colors.whiteSmoke};
  color: ${colors.tangaroa};

  border-bottom: solid 1px ${colors.whisper};

  ${above('mobile')} {
    display: none;
  }
`;

const MobileShareViewWarnings = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  justify-content: space-between;

  color: ${colors.tangaroa};

  border-bottom: solid 1px ${colors.whisper};

  ${above('mobile')} {
    display: none;
  }
`;

const ConditionalMobileAppLink = withRouter<
  RouteComponentProps & { app: App; isClock: boolean }
>(({ app, location }) => {
  const { jt } = useI18n();
  const accessKey = extractAccessKeyFromLocation(location as any);

  // This is to make the translation work, it returns an array when
  // inlining JSX elements
  const linebreak = <br key="linebreak" />;

  return (
    <MobileAppInfoText>
      <PhoneIcon src={phoneIcon} alt="" />
      {jt`Available to install from ${linebreak} the Fitbit mobile app`}
      <OpenAppLink appId={app.id} appType={app.type} accessKey={accessKey} />
    </MobileAppInfoText>
  );
});

const PremiumNoticeContainer = styled.div`
  margin-bottom: 30px;

  ${below('mobile')} {
    margin-bottom: 10px;
  }
`;

export const DeveloperLink = styled(NoStyleLink)`
  color: ${colors.irisBlue};
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.57;
  display: inline-block;
`;

export const DeveloperName = styled.div`
  color: ${colors.irisBlue};
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.57;
  display: inline-block;
`;

const DetailsBanner: React.FC<ChildProps> = ({ app, data }) => {
  const { t } = useI18n();
  const isClock = app.type === 'CLOCK';
  const icon = !isClock && app.icon && app.icon.uri;
  const link = `https://gallery.fitbit.com/details/${app.id}`;

  const warnings = [];

  if (app.isPrivate && !app.bypassPrivateAppWarning) {
    warnings.push({
      title: t`Private app`,
      text: () => (
        <React.Fragment>
          {t`The content has not been reviewed by Fitbit.`}
        </React.Fragment>
      ),
      boldEndText: t`Only install applications from trusted sources.`
    });
  }

  if (app.isPaid) {
    warnings.push({
      title: t`Payment required`,
      text: () => (
        <React.Fragment>
          {t`This app requires payment and is managed by a 3rd-party developer that is not endorsed by Fitbit.`}
        </React.Fragment>
      ),
      boldEndText: t`Proceed with caution.`
    });
  }

  const shareIcons = (
    <ShareIcons>
      <FacebookShareIcon link={link} />
      <TwitterShareIcon link={link} />
      <CopyShareIcon link={link} />
      {app.isPrivate && !app.bypassPrivateAppWarning && (
        <ReportAppButton app={app} />
      )}
    </ShareIcons>
  );

  return (
    <Wrapper>
      <Section>
        <BannerImageSection>
          <ScreenshotSelector
            images={getAppScreenshots(app)}
            supportedDevices={app.availability.deviceTypes}
          />
        </BannerImageSection>
        <BannerInfo>
          <BannerInfoHeader>
            {icon && <Icon src={icon} alt="" />}
            <div>
              {app.isPremium && (
                <PremiumNoticeContainer>
                  <PremiumNotice text={t`Available only to Premium Users`} />
                </PremiumNoticeContainer>
              )}
              <BannerTitle>{app.name}</BannerTitle>
              {!data.isEEAUser ? (
                <DeveloperLink to={`/developer/${app.developer.id}`}>
                  {app.developer.name}
                </DeveloperLink>
              ) : (
                <DeveloperName>{app.developer.name} </DeveloperName>
              )}
              <SupportedDevices app={app} />
              <DesktopView>{shareIcons}</DesktopView>
            </div>
          </BannerInfoHeader>
          <ConditionalMobileAppLink app={app} isClock={isClock} />
          <DesktopView>
            {warnings.length > 0 &&
              warnings.map((warning, index) => (
                <Disclaimer key={`disclaimer_${index}`} {...warning} />
              ))}
          </DesktopView>
        </BannerInfo>
      </Section>
      <MobileShareViewWarnings>
        {warnings.length > 0 &&
          warnings.map((warning, index) => (
            <Disclaimer key={`disclaimer_${index}`} {...warning} />
          ))}
      </MobileShareViewWarnings>
      <MobileShareView>{shareIcons}</MobileShareView>
    </Wrapper>
  );
};

export default graphql<
  Props,
  GetEEAUserResponse,
  GetEEAUserVariables,
  ChildProps
>(getEEAUser, {
  options: (props) => ({
    variables: {
      clientIpAddress: props.clientIpAddress
    }
  })
})(DetailsBanner);
