import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { App } from '../types';

import MobileModal from './MobileModal';

import { colors } from '../style-constants';
import { below, above } from '../media-query-utils';
import { useMatchMedia, useI18n } from '../hooks';

import { Badge, FitLink } from './basic-components';
import Popup from './Popup';

import check from '../images/icons/check-green.svg';

import smallCharge3 from '../images/device_images/small-charge_3.png';
import smallCharge4 from '../images/device_images/small-charge_4.png';
import smallCharge5 from '../images/device_images/small-charge_5.png';
import smallCharge6 from '../images/device_images/small-charge_6.png';
import smallIonic from '../images/device_images/small-ionic.png';
import smallVersa from '../images/device_images/small-versa.png';
import smallVersaLite from '../images/device_images/small-versa_lite.png';
import smallVersa2 from '../images/device_images/small-versa_2.png';
import smallInspire from '../images/device_images/small-inspire.png';
import smallInspireHR from '../images/device_images/small-inspire_hr.png';
import smallAce2 from '../images/device_images/small-ace_2.png';
import smallAce3 from '../images/device_images/small-ace_3.png';
import smallVersa3 from '../images/device_images/small-versa_3.png';
import smallVersa4 from '../images/device_images/small-versa_4.png';
import smallSense from '../images/device_images/small-sense.png';
import smallSense2 from '../images/device_images/small-sense_2.png';
import smallLuxe from '../images/device_images/small-luxe.png';
import smallNyota from '../images/device_images/small-nyota.png';
import logoCharge3 from '../images/device_images/logo-charge_3.png';
import logoCharge4 from '../images/device_images/logo-charge_4.png';
import logoCharge5 from '../images/device_images/logo-charge_5.png';
import logoCharge6 from '../images/device_images/logo-charge_6.png';
import logoIonic from '../images/device_images/logo-ionic.png';
import logoVersa from '../images/device_images/logo-versa.png';
import logoVersa2 from '../images/device_images/logo-versa_2.png';
import logoVersa3 from '../images/device_images/logo-versa_3.svg';
import logoVersa4 from '../images/device_images/logo-versa_4.png';
import logoVersaLite from '../images/device_images/logo-versa_lite.png';
import logoInspire from '../images/device_images/logo-inspire.png';
import logoInspireHR from '../images/device_images/logo-inspire_hr.png';
import logoAce2 from '../images/device_images/logo-ace_2.png';
import logoAce3 from '../images/device_images/logo-ace_3.png';
import logoSense from '../images/device_images/logo-sense.svg';
import logoSense2 from '../images/device_images/logo-sense_2.png';
import logoLuxe from '../images/device_images/logo-luxe.png';
import logoNyota from '../images/device_images/logo-nyota.svg';

type SupportedDevicesProps = {
  app: App;
};

const convertNameToLink: (name: string) => string = (name) => {
  switch (name) {
    case 'inspirehr':
      return 'inspire';
    case 'versalite':
      return 'versa-lite';
    case 'versa2':
      return 'versa';
    default:
      return name;
  }
};

type SmallDeviceImage = string;
type DeviceLogoImage = string;
type DeviceLogoWidth = number; // These are based on the design

const supportedDeviceImages: {
  [index: string]: [SmallDeviceImage, DeviceLogoImage, DeviceLogoWidth];
} = {
  ANTARES: [smallCharge3, logoCharge3, 168],
  KOSHI: [smallCharge4, logoCharge4, 168],
  CENTAURI: [smallInspire, logoInspire, 146],
  CENTAURI_HR: [smallInspireHR, logoInspireHR, 176],
  CENTAURI_KIDS: [smallAce2, logoAce2, 128],
  PROXIMA_KIDS: [smallAce3, logoAce3, 128],
  HIGGS: [smallIonic, logoIonic, 123],
  MESON: [smallVersa, logoVersa, 137],
  GEMINI: [smallVersaLite, logoVersaLite, 137],
  MIRA: [smallVersa2, logoVersa2, 150],
  ATLAS: [smallVersa3, logoVersa3, 150],
  VULCAN: [smallSense, logoSense, 150],
  BUZZ: [smallLuxe, logoLuxe, 150],
  MORGAN: [smallCharge5, logoCharge5, 168],
  NYOTA: [smallNyota, logoNyota, 168],
  RHEA: [smallSense2, logoSense2, 150],
  HERA: [smallVersa4, logoVersa4, 150],
  MELROY: [smallCharge6, logoCharge6, 168]
};

const SupportedDevicesContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 40px;

  ${below('mobile')} {
    display: ${({ active }) => (active ? 'flex' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;

    z-index: 2;
  }
`;

const SupportedDevicePopup = styled(Popup)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const PopupDeviceImage = styled.img`
  width: 54px;
  height: 60px;
  margin-left: 30px;

  object-fit: contain;
`;

const PopupLogo = styled.img`
  height: auto;
  margin-bottom: 10px;
`;

const SupportedDeviceLearnMore = FitLink;

const PopupLogoLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const SupportedDevicePopupContent = styled.div`
  display: flex;
  ${({
    size: { width, height }
  }: {
    size: { width: number; height: number };
  }) => css`
    width: ${width}px;
    height: ${height}px;
  `}

  align-items: center;
}

  ${below('mobile')} {
    width: 100%;
  }
`;

const SupportedDeviceBadge = styled(Badge)`
  margin: 0;

  user-select: none;

  ${below('mobile')} {
    display: none;
  }
`;

const MobileSupportedDeviceBadge = styled(Badge)`
  display: flex;
  width: fit-content;
  margin: 0;
  margin-bottom: 20px;
  padding: 9px 12px;

  border-radius: 12px;
  line-height: 1.4;

  cursor: pointer;

  ${above('mobile')} {
    display: none;
  }
`;

const Check = styled.img.attrs(() => ({
  src: check,
  alt: ''
}))`
  margin-right: 8px;
`;

const MobileSupportedDeviceContainer = styled.div`
  position: relative;
  width: 100%;

  background-color: ${colors.white};
`;

const SupportedDevice: React.FC<{
  type: string;
  productName: string;
  isMobile: boolean;
}> = ({ type, productName, isMobile, children }) => {
  const { t } = useI18n();
  const [deviceImage, logo, logoWidth] = supportedDeviceImages[type];

  const size = { width: logoWidth > 150 ? 306 : 290, height: 115 };

  const deviceContent = (
    <SupportedDevicePopupContent size={size}>
      <PopupDeviceImage src={deviceImage} />
      <PopupLogoLinkContainer>
        <PopupLogo src={logo} width={logoWidth} />
        <SupportedDeviceLearnMore
          to={`https://www.fitbit.com/${convertNameToLink(
            productName.toLowerCase().replace('_', '')
          )}`}
          external
        >
          {t`Learn More`}
        </SupportedDeviceLearnMore>
      </PopupLogoLinkContainer>
    </SupportedDevicePopupContent>
  );

  if (isMobile) {
    return (
      <MobileSupportedDeviceContainer>
        {deviceContent}
      </MobileSupportedDeviceContainer>
    );
  }

  return (
    <SupportedDevicePopup
      size={{ ...size, height: size.height + 5 }}
      target={<SupportedDeviceBadge>{children}</SupportedDeviceBadge>}
    >
      {deviceContent}
    </SupportedDevicePopup>
  );
};

const SupportedDevices: React.FC<SupportedDevicesProps> = ({ app }) => {
  const { t, ngettext, msgid } = useI18n();
  const [active, setActive] = useState(false);
  const [isMobile] = useMatchMedia(`(max-width: 600px)`);

  const toggleMobileModalActive = useCallback(() => setActive(!active), [
    active
  ]);

  const supportedDevices = app.availability.deviceTypes
    .filter(({ type }) => !!supportedDeviceImages[type]) // Check for assets before trying to render
    .sort((a, b) => (a.productName > b.productName ? 1 : -1))
    .map(({ type, productName }) => (
      <SupportedDevice
        key={type}
        type={type}
        productName={productName}
        isMobile={isMobile}
      >
        {productName.replace(/_/g, ' ')}
      </SupportedDevice>
    ));

  const mobileSupportedDevices = (
    <MobileModal
      title={
        // translator: Title for list of available devices
        t`Available For`
      }
      active={active}
      toggle={toggleMobileModalActive}
    >
      {supportedDevices}
    </MobileModal>
  );

  const desktopSupportedDevices = (
    <SupportedDevicesContainer id="supportedDevicesContainer">
      {supportedDevices}
    </SupportedDevicesContainer>
  );

  const devicesCount = app.availability.deviceTypes.length;
  return (
    <React.Fragment>
      <MobileSupportedDeviceBadge onClick={toggleMobileModalActive} inverse>
        <Check />
        {ngettext(
          msgid`${devicesCount} device`,
          `${devicesCount} devices`,
          devicesCount
        )}
      </MobileSupportedDeviceBadge>
      {isMobile ? active && mobileSupportedDevices : desktopSupportedDevices}
    </React.Fragment>
  );
};

export default SupportedDevices;
