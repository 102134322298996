import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../hooks';
import { NoStyleLink } from './basic-components';
import { colors } from '../style-constants';
import premiumIcon from '../images/icons/premium.svg';

interface PremiumNoticeProps {
  text?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const NoticeText = styled.div`
  margin-left: 6px;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${colors.premiumYellow};
`;

const TryPremiumButton = styled(NoStyleLink)`
  margin-left: 16px;
  align-items: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  color: ${colors.premiumYellow};
`;

const PremiumIcon = styled.img.attrs(() => ({
  src: premiumIcon
}))`
  width: 12px;
  height: 12px;
`;

const PremiumNotice: React.FC<PremiumNoticeProps> = (props) => {
  const { t } = useI18n();
  const { text = t`Some clocks only available to Premium Users` } = props;

  return (
    <Container>
      <PremiumIcon />
      <NoticeText>{text}</NoticeText>
      <TryPremiumButton
        to={'https://www.fitbit.com/global/us/products/services/premium'}
        external
      >
        {t`Try Premium`}
      </TryPremiumButton>
    </Container>
  );
};

export default PremiumNotice;
