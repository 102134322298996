import React from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';

import versa from '../images/device_images/versa-grey.png';
import versa2 from '../images/device_images/versa_2.png';
import versa3 from '../images/device_images/versa_3.png';
import versa4 from '../images/device_images/versa_4.png';
import sense from '../images/device_images/sense.png';
import sense2 from '../images/device_images/sense_2.png';
import ionic from '../images/device_images/ionic.png';
import luxe from '../images/device_images/luxe.png';
import inspire from '../images/device_images/inspire.png';
import ace2 from '../images/device_images/ace_2.png';
import ace3 from '../images/device_images/ace_3.png';
import charge3 from '../images/device_images/charge_3.png';
import charge4 from '../images/device_images/charge_4.png';
import charge5 from '../images/device_images/charge_5.png';
import charge6 from '../images/device_images/charge_6.png';
import nyota from '../images/device_images/nyota.png';

import { Screenshot } from '../graphql/getApp';
import { DeviceTypes } from '../types';

type ResizeProps = {
  width?: number;
  height?: number;
};

type ImageComponentProps = ResizeProps & {
  image: string;
  top?: number;
  left?: number;
  isSquircle?: boolean;
};

type MaxSizeProps = {
  maxWidth: number;
  maxHeight: number;
};

type DeviceOptionsProps = {
  noResize?: boolean;
};

type DeviceFrameProps = ImageComponentProps & MaxSizeProps & DeviceOptionsProps;

type BaseDeviceProps = MaxSizeProps &
  DeviceOptionsProps & {
    image: string;
    className?: string;
  };

type DeviceProps = DeviceOptionsProps & {
  screenshot: Screenshot;
  className?: string;
  supportedDevices?: DeviceTypes;
};

type DeviceComponent = React.FC<DeviceProps>;

const DEVICE_PRIORITIES: string[] = [
  'MIRA',
  'MESON',
  'KOSHI',
  'GEMINI',
  'HIGGS',
  'ANTARES',
  'CENTAURI_HR',
  'CENTAURI',
  'CENTAURI_KIDS',
  'RHEA',
  'HERA',
  'VULCAN',
  'ATLAS',
  'BUZZ',
  'MELROY',
  'MORGAN',
  'NYOTA'
];

const Container = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

const setScale = (scale: number, verticalAlign?: boolean) => css`
  transform: scale(${scale}) translateX(-50%)
    ${verticalAlign ? 'translateY(-50%)' : ''};
`;

const DeviceFrame = styled.div<DeviceFrameProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${prop('maxWidth')}px;
  height: ${prop('maxHeight')}px;

  ${setScale(1, true)}

  ${({ noResize }) => {
    if (noResize) {
      return css`
        position: relative;
        top: unset;
        ${setScale(1)}
      `;
    }

    const fallbackScaling = [];
    for (let i = 0; i <= 8; i += 1) {
      fallbackScaling.push(css`
        @media (max-width: ${1440 - i * 100}px) {
          ${setScale(1 - i * 0.075, true)}
        }
      `);
    }

    return css`
      ${fallbackScaling}
      transition: all 100ms;
    `;
  }}

  transform-origin: 0 0;

  background-image: url(${prop('image')});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const ScreenshotImage = styled.div<ImageComponentProps>`
  position: absolute;
  ${({ top, left, width, height }) => css`
    top: ${top}px;
    left: ${left}px;
    width: ${width}px;
    height: ${height}px;
  `}

  background-image: url(${prop('image')});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  ${({ isSquircle }) => {
    if (isSquircle) {
      return `clip-path: url(#squircleClipPath); -webkit-clip-path: url(#squircleClipPath);`;
    }

    return;
  }}
`;

const BaseDevice: React.FC<BaseDeviceProps> = ({ className, ...props }) => (
  <Container className={className}>
    <DeviceFrame {...props} />
  </Container>
);

export const Versa: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={versa} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={101}
      left={52}
      width={142}
      height={142}
    />
  </BaseDevice>
);

export const Versa2: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={versa2} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={107}
      left={50}
      width={150}
      height={150}
    />
  </BaseDevice>
);

export const Versa3: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={versa3} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={108}
      left={37}
      width={173}
      height={173}
      isSquircle={true}
    />
  </BaseDevice>
);

export const Versa4: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={versa4} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={108}
      left={37}
      width={173}
      height={173}
      isSquircle={true}
    />
  </BaseDevice>
);

export const Sense: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={sense} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={108}
      left={37}
      width={173}
      height={173}
      isSquircle={true}
    />
  </BaseDevice>
);

export const Sense2: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={sense2} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={108}
      left={37}
      width={173}
      height={173}
      isSquircle={true}
    />
  </BaseDevice>
);

export const Ionic: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={ionic} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={124}
      left={45}
      width={157}
      height={113}
    />
  </BaseDevice>
);

export const Inspire: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={inspire} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={114}
      left={94}
      width={62}
      height={111}
    />
  </BaseDevice>
);

export const Luxe: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={luxe} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={140}
      left={87}
      width={72}
      height={97}
    />
  </BaseDevice>
);

export const Ace2: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={ace2} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={103}
      left={90}
      width={69}
      height={123}
    />
  </BaseDevice>
);

export const Ace3: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={ace3} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={103}
      left={90}
      width={69}
      height={123}
    />
  </BaseDevice>
);

export const Charge3: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={charge3} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={100}
      left={85}
      width={77}
      height={138}
    />
  </BaseDevice>
);

export const Charge4: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={charge4} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={111}
      left={85}
      width={78}
      height={138}
    />
  </BaseDevice>
);

export const Charge5: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={charge5} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={113}
      left={79}
      width={88}
      height={153}
    />
  </BaseDevice>
);

export const Charge6: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={charge6} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={113}
      left={79}
      width={88}
      height={153}
    />
  </BaseDevice>
);

export const Nyota: DeviceComponent = ({ screenshot, ...props }) => (
  <BaseDevice image={nyota} maxWidth={246} maxHeight={381} {...props}>
    <ScreenshotImage
      image={screenshot.uri}
      top={113}
      left={87}
      width={71}
      height={138}
    />
  </BaseDevice>
);

const getDeviceSameWidth = (
  Device: DeviceComponent,
  deviceImageWidth: number,
  screenshotWidth: number
): DeviceComponent | undefined =>
  deviceImageWidth === screenshotWidth ? Device : undefined;

export const DeviceForScreenshot: React.FC<DeviceProps> = ({
  screenshot,
  supportedDevices,
  ...props
}) => {
  const { width } = screenshot;
  const squircleDevices = [Versa3, Sense, Versa4, Sense2];

  let Device;

  if (supportedDevices) {
    const sortedDevices = supportedDevices.sort(
      (a, b) =>
        DEVICE_PRIORITIES.indexOf(a.type) - DEVICE_PRIORITIES.indexOf(b.type)
    );

    for (const { type } of sortedDevices) {
      let SupportedDevice;

      switch (type) {
        case 'CENTAURI':
        case 'CENTAURI_HR':
          SupportedDevice = getDeviceSameWidth(Inspire, 72, width);
          break;
        case 'CENTAURI_KIDS':
          SupportedDevice = getDeviceSameWidth(Ace2, 72, width);
          break;
        case 'PROXIMA_KIDS':
          SupportedDevice = getDeviceSameWidth(Ace3, 72, width);
          break;
        case 'ANTARES':
          SupportedDevice = getDeviceSameWidth(Charge3, 100, width);
          break;
        case 'KOSHI':
          SupportedDevice = getDeviceSameWidth(Charge4, 100, width);
          break;
        case 'MIRA':
          SupportedDevice = getDeviceSameWidth(Versa2, 300, width);
          break;
        case 'HIGGS':
          SupportedDevice = getDeviceSameWidth(Ionic, 348, width);
          break;
        case 'VULCAN':
          SupportedDevice = getDeviceSameWidth(Sense, 336, width);
          break;
        case 'RHEA':
          SupportedDevice = getDeviceSameWidth(Sense2, 336, width);
          break;
        case 'ATLAS':
          SupportedDevice = getDeviceSameWidth(Versa3, 336, width);
          break;
        case 'HERA':
          SupportedDevice = getDeviceSameWidth(Versa4, 336, width);
          break;
        case 'BUZZ':
          SupportedDevice = getDeviceSameWidth(Luxe, 124, width);
          break;
        case 'MORGAN':
          SupportedDevice = getDeviceSameWidth(Charge5, 184, width);
          break;
        case 'NYOTA':
          SupportedDevice = getDeviceSameWidth(Nyota, 124, width);
          break;
        case 'MELROY':
          SupportedDevice = getDeviceSameWidth(Charge6, 184, width);
          break;
        case 'MESON':
        default:
          SupportedDevice = getDeviceSameWidth(Versa, 300, width);
      }

      if (SupportedDevice) {
        Device = SupportedDevice;
        break;
      }
    }
  }

  if (!Device) {
    switch (width) {
      case 336:
        Device = Sense;
        break;
      case 348:
        Device = Ionic;
        break;
      default:
        Device = Versa;
    }
  }

  const hasSquircleImage = squircleDevices.includes(Device);

  return (
    <React.Fragment>
      {hasSquircleImage && (
        <svg
          width="0"
          height="0"
          viewBox="0 0 336 336"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath
              id="squircleClipPath"
              clipPathUnits="objectBoundingBox"
              transform="scale(0.002976 0.002976)"
            >
              <path
                d="M333.475 112.584C336.842 149.675 336.842 186.321 333.475 223.416C330.973 250.971 327.936 283.481 305.718 305.703C283.494 327.934 250.98 330.971 223.415 333.473C186.321 336.842 149.678 336.842 112.584 333.473C85.0257 330.971 52.5216 327.937 30.2938 305.719C8.06595 283.494 5.02921 250.981 2.52742 223.416C-0.842473 186.321 -0.842473 149.675 2.52742 112.584C5.02921 85.0227 8.06275 52.5185 30.2842 30.2971C52.5056 8.06277 85.0193 5.02922 112.584 2.52742C149.678 -0.842475 186.321 -0.842475 223.415 2.52742C250.974 5.02922 283.481 8.06277 305.702 30.281C327.936 52.5057 330.97 85.0195 333.475 112.584Z"
                fill="black"
              />
            </clipPath>
          </defs>
        </svg>
      )}
      <Device screenshot={screenshot} {...props} />
    </React.Fragment>
  );
};
