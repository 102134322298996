import React from 'react';
import styled, { css } from 'styled-components';

import { DeviceForScreenshot } from './Devices';
import { Screenshot } from '../graphql/getApp';
import { colors } from '../style-constants';
import { DeviceTypes } from '../types';

const DEFAULT_WIDTH = 44;
const DEFAULT_HEIGHT = 44;

type Props = {
  images: Screenshot[];
  supportedDevices: DeviceTypes;
};

interface State {
  selectedScreenshot: Screenshot;
}

const Selector = styled.div`
  display: flex;
  width: auto;
  margin-top: 30px;

  justify-content: center;
`;

const MiniScreenshot = styled.img`
  height: ${({ height = DEFAULT_HEIGHT }) => height}px;
  width: ${({ width = DEFAULT_WIDTH }) => width}px;

  & + & {
    margin-left: 15px;
  }

  border: solid 4px ${colors.white};

  ${({ selected }: { selected: boolean }) =>
    selected
      ? css`
          border: solid 2px ${colors.white};
          outline: solid 2px ${colors.irisBlue};
        `
      : ''}
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default class ScreenshotSelector extends React.Component<Props, State> {
  public state = {
    selectedScreenshot: this.props.images[0]
  };

  private calculateDimmensions = ({
    width,
    height
  }: {
    width: number;
    height: number;
  }) => {
    if (width > height) {
      return {
        width: DEFAULT_WIDTH,
        height: (height / width) * DEFAULT_HEIGHT
      };
    } else if (height > width) {
      return {
        width: (width / height) * DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT
      };
    }

    return {
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT
    };
  };

  private selectScreenshot = (image: Screenshot) =>
    this.setState({ selectedScreenshot: image });

  public render() {
    const { images, supportedDevices } = this.props;

    const { selectedScreenshot } = this.state;

    return (
      <ImageContainer>
        <DeviceForScreenshot
          screenshot={selectedScreenshot}
          supportedDevices={supportedDevices}
          noResize
        />
        {images && images.length > 1 && (
          <Selector>
            {images.map((image, index) => (
              <MiniScreenshot
                key={index}
                src={image.uri}
                {...this.calculateDimmensions(image)}
                selected={image === selectedScreenshot}
                onClick={() =>
                  /* tslint:disable-next-line:jsx-no-lambda */
                  this.selectScreenshot(image)
                }
              />
            ))}
          </Selector>
        )}
      </ImageContainer>
    );
  }
}
