import React from 'react';
import Helmet from 'react-helmet';
import { graphql, ChildDataProps } from 'react-apollo';
import styled from 'styled-components';
import { UAParser } from 'ua-parser-js';

import {
  getAppDetails,
  GetAppDetailsResponse,
  GetAppDetailsVariables
} from '../graphql/getAppDetails';

import {
  BannerTitle,
  BaseSection,
  FitLink
} from '../components/basic-components';
import { above } from '../media-query-utils';
import { isWeb } from '../sharedConfig';
import { useOpenwith, useI18n } from '../hooks';
import { ErrorPage } from './ErrorPage';
import { PageNotFound } from './404';
import { LoadingPage } from '../components/LoadingPage';

export type MobileAppRedirectProps = {
  appId: string;
  accessKey?: string;
};

type ChildProps = ChildDataProps<
  MobileAppRedirectProps,
  GetAppDetailsResponse,
  GetAppDetailsVariables
>;

const getOS = () => {
  const userAgent = new UAParser(navigator.userAgent);
  const { name: os } = userAgent.getOS();

  return os;
};

const createMobileLink = ({
  openwith,
  appId,
  appType,
  accessKey
}: {
  openwith: string;
  appId: string;
  appType: string;
  accessKey?: string;
}) => {
  const os = getOS();

  // services will be opened as apps
  if (appType === 'SERVICE') {
    appType = 'APP';
  }
  const path = `${appType}/${appId}?directLink=true&key=${accessKey}`;

  let mobileLink;
  if (os === 'Android') {
    let packageFallback = '';
    if (openwith === 'fitbit') {
      packageFallback = `package=com.fitbit.FitbitMobile;`;
    }

    mobileLink = `intent://gallery?path=${path}#Intent;scheme=fitbit;${packageFallback}end`;
  } else {
    mobileLink = `${openwith}://gallery?path=${encodeURIComponent(path)}`;
  }

  return mobileLink;
};

const Redirect: React.FC<{
  to: string;
  fallbackLink: string;
}> = ({ to, fallbackLink }) => {
  if (isWeb) {
    // On iOs Chrome does not know how to open a deep-link from the
    // window.location but it can open an URL from a link. As an
    // alternate method we create a link and click it.
    // Instead of 'window.location.replace(to);' we do:
    const link = window.document.createElement('a');
    // Make the link hidden. We already have a visible link fo this.
    link.hidden = true;
    link.href = to;
    // Append the link to the document otherwise events won't work
    // in some browsers.
    window.document.body.appendChild(link);
    // Trigger the click event.
    link.click();

    setTimeout(() => window.location.replace(fallbackLink), 0);
  }

  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

const Section = styled(BaseSection)`
  display: flex;
  height: 100%;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

const Links = styled.div`
  margin-top: 20px;
`;

const AppLink = styled(FitLink)`
  flex: 1;
  text-align: center;
  margin-left: 10px;

  ${above('mobile')} {
    display: none;
  }
`;

export const OpenAppLink: React.FC<{
  appId: string;
  appType: string;
  accessKey?: string;
}> = ({ appId, appType, accessKey }) => {
  const { t } = useI18n();
  const openwith = useOpenwith();

  if (!isWeb) {
    return null;
  }

  const os = getOS();
  const isAndroid = os === 'Android';

  const linkProps = isAndroid
    ? {
        to: createMobileLink({
          openwith,
          appId,
          appType
        }),
        external: true
      }
    : {
        to: `/details/${appId}/openapp?key=${accessKey}`,
        target: '_blank'
      };

  return (
    <AppLink rel="nofollow" {...linkProps}>
      {t`Open App`}
    </AppLink>
  );
};

const MobileAppRedirect: React.FC<ChildProps> = ({ appId, data }) => {
  const { t } = useI18n();
  const openwith = useOpenwith();
  const { loading, error, app } = data;

  if (loading || !isWeb) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!app) {
    return <PageNotFound />;
  }

  const mobileLink = createMobileLink({ openwith, appType: app.type, appId });
  const fallbackLink = 'https://www.fitbit.com/setup';

  return (
    <Section>
      <Redirect to={mobileLink} fallbackLink={fallbackLink} />
      <BannerTitle>{t`Opening the Fitbit app`}</BannerTitle>
      <Links>
        <FitLink to={mobileLink} external>
          {t`Try again`}
        </FitLink>
        <span> / </span>
        <FitLink to={fallbackLink} external>
          {t`Get the Fitbit app`}
        </FitLink>
      </Links>
    </Section>
  );
};

export default graphql<
  MobileAppRedirectProps,
  GetAppDetailsResponse,
  GetAppDetailsVariables,
  ChildProps
>(getAppDetails, {
  options: ({ appId, accessKey }) => ({
    variables: {
      id: appId,
      key: accessKey
    }
  })
})(MobileAppRedirect);
