import React, { ReactNode } from 'react';
import styled from 'styled-components';

import alertIcon from '../images/icons/ic_info.png';

type DisclaimerProps = {
  title: string;
  text: () => ReactNode;
  boldEndText: string;
};

const WarningContainer = styled.div`
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  box-shadow: 0px 4px 16px rgba(17, 37, 48, 0.05);
  border-radius: 12px;
  margin: 12px 12px 12px 0px;
`;

const WarningText = styled.div`
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: -0.32px;
  padding: 15px 15px 0px 15px;
`;

const BoldWarningText = styled.div`
  color: #000000;
  font-weight: bold;

  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.32px;
  padding: 0px 15px 15px 15px;
`;

const WarningTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.32px;

  color: #112530;
  padding: 16px 0px 0px 16px;
`;

const WarningIcon = styled.img`
  align-self: center;
  margin-left: auto;
`;

const NoticeHeaderContainer = styled.div`
  display: flex;

  width: 100%;
`;

const Disclaimer: React.FC<DisclaimerProps> = (props) => {
  return (
    <WarningContainer>
      <NoticeHeaderContainer>
        <WarningTitle>{props.title}</WarningTitle>
        <WarningIcon src={alertIcon} />
      </NoticeHeaderContainer>

      <WarningText>{props.text()}</WarningText>
      <BoldWarningText>{props.boldEndText}</BoldWarningText>
    </WarningContainer>
  );
};

export default Disclaimer;
