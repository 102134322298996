import React from 'react';
import { graphql, ChildDataProps } from 'react-apollo';
import Helmet from 'react-helmet';

import DetailsBanner from '../components/DetailsBanner';
import DetailsInfo from '../components/DetailsInfo';
import { ActiveNavItem } from '../components/Header';

import {
  getAppDetails,
  GetAppDetailsResponse,
  GetAppDetailsVariables
} from '../graphql/getAppDetails';
import {
  InfoText,
  CalloutSection,
  ButtonLink
} from '../components/basic-components';
import { ErrorPage } from './ErrorPage';
import { PageNotFound } from './404';
import { LoadingPage } from '../components/LoadingPage';
import { useI18n } from '../hooks';
import { getAppScreenshots } from '../utils';

export type DetailsProps = {
  id: string;
  accessKey: any;
  clientIpAddress?: any;
};

type ChildProps = ChildDataProps<
  DetailsProps,
  GetAppDetailsResponse,
  GetAppDetailsVariables
>;

const Details: React.FC<ChildProps> = (props) => {
  const { t } = useI18n();
  const { data } = props;
  const { loading, error, app } = data;

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!app) {
    return <PageNotFound />;
  }

  const activeNavItem = app.type === 'CLOCK' ? 'clocks' : 'apps';
  const hasCategory = app.categories && app.categories.length !== 0;
  // translator: For example - "Exercise by Fitbit"
  const title = t`${app.name} by ${app.developer.name}`;
  const mainImage = getAppScreenshots(app)[0].uri;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={app.description} />
        <meta property="og:description" content={app.description} />
        <meta property="og:image" content={mainImage} />
      </Helmet>
      <ActiveNavItem item={activeNavItem} />
      <DetailsBanner app={app} clientIpAddress={props.clientIpAddress} />
      <DetailsInfo app={app} clientIpAddress={props.clientIpAddress} />
      {hasCategory && (
        <CalloutSection>
          <h2>{app.categories[0].name}</h2>
          <InfoText>{/*TODO: Get InfoText for category*/}</InfoText>
          <ButtonLink to={`/category/${app.categories[0].id}`}>
            {t`See More`}
          </ButtonLink>
        </CalloutSection>
      )}
    </React.Fragment>
  );
};

export default graphql<
  DetailsProps,
  GetAppDetailsResponse,
  GetAppDetailsVariables,
  ChildProps
>(getAppDetails, {
  options: (props) => ({
    variables: {
      id: props.id,
      key: props.accessKey,
      clientIpAddress: props.clientIpAddress
    }
  })
})(Details);
