import gql from 'graphql-tag';
import { App } from '../types';

export type GetAppDetailsResponse = {
  app: App;
};

export type GetAppDetailsVariables = {
  id: string;
  key?: string;
  clientIpAddress?: string;
};

const appDetails = gql`
  fragment AppDetails on App {
    id
    name
    availability {
      deviceTypes {
        type
        productName
        displayInfo {
          resolution {
            width
            height
          }
        }
      }
    }
    previewImage(scale: 1.0) {
      uri
    }
    icon(width: 120, height: 120) {
      uri
    }
    developer {
      id
      name
      website
      isSecondParty
    }
    description
    isPrimary
    contactUrl
    screenshots {
      uri
      width
      height
    }
    type
    categories {
      id
      name
    }
    isPaid
    isPrivate
    bypassPrivateAppWarning
    isPremium
    # latestVersion {
    #   createdAt
    #   versionString
    # }
  }
`;

export const getAppDetails = gql`
  query app($id: ID!, $key: String, $clientIpAddress: String) {
    app(id: $id, key: $key, clientIpAddress: $clientIpAddress) {
      ...AppDetails
    }
  }
  ${appDetails}
`;
